<template lang="pug">
form.children-form(:class="formType")
  h3.text-center 免費英文健檢
  .form-content
    .form-input
      template(v-if="formType === 'sincechildform'")
        .icon: img(:src="require('@/assets/childrensday2024/member.svg')")
      template(v-else)
        .icon: img(:src="require('@/assets/event/trial/member-orange.svg')")
      //- .icon: img(:src="require('@/assets/event/trial/member-orange.svg')")
      input(type="text", placeholder="姓名（必填）" v-model="form.parentName")
    .form-input
      template(v-if="formType === 'sincechildform'")
        .icon: img(:src="require('@/assets/childrensday2024/phone.svg')")
      template(v-else)
        .icon: img(:src="require('@/assets/event/trial/phone-orange.svg')")
      input(type="phone", placeholder="手機（必填）" v-model="form.parentPhone")
    .form-input
      template(v-if="formType === 'sincechildform'")
        .icon: img(:src="require('@/assets/childrensday2024/mail.svg')")
      template(v-else)
        .icon: img(:src="require('@/assets/event/trial/mail-orange.svg')")
      input(type="email", placeholder="信箱（必填）" v-model="form.parentEmail")
    .form-descriptive
      h4
        span 好禮 2 選 1
      ul
        li
          img(:src="require('@/assets/childrensday2024/gift1.png')", alt="gift1")
          div.relative
            template(v-if="formType === 'sincechildform'")
              img(class="shape" :src="require('@/assets/childrensday2024/free-blue.png')", alt="free")
            template(v-else)
              img(class="shape" :src="require('@/assets/childrensday2024/free.png')", alt="free")
            p 繪本有聲書
        li
          img(:src="require('@/assets/childrensday2024/gift2.png')", alt="gift2")
          div.relative
            template(v-if="formType === 'sincechildform'")
              img(class="shape" :src="require('@/assets/childrensday2024/free-blue.png')", alt="free")
            template(v-else)
              img(class="shape" :src="require('@/assets/childrensday2024/free.png')", alt="free")
            p 英文學習單
    .form-privacy
      input#privacy-upper(v-model="form.privacyPolicyCheck",type="checkbox")
      label(for="privacy-upper")
        img(:src="require('@/assets/event/trial/check.svg')")
      span 我同意 WUWOW 線上英文&ensp;
        router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策
    button(type="button" @click="submitForm(form)") 免費搶先預約
    p.text-center
      a(href="tel:0800885315") 0800 885 315
      | 撥打專線，立即有專人為您服務
</template>

<script>
import lioshutanApi from '@/api';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { getEventMediaSource } from '@/utils/mediaSourse';
import httpCodeConstants from '@/constants/httpCode';

export default {
  name: 'CheckForm',
  props: {
    formType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      privacyUrl: '/privacy',
      aff_click_id: '',
      form: {
        parentName: '',
        parentPhone: '',
        parentEmail: '',
        privacyPolicyCheck: true,
      },
    };
  },
  methods: {
    fieldCheck(email, phone, privacyPolicyCheck, formdata) {
      // 只檢查家長姓名
      if (!formdata.parentName) {
        alertFailedMessage('請留下您姓名');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },
    hasLeaveMessage() {
      localStorage.setItem('hasWritedPhone', true);
    },
    getNoteInfo(formdata) {
      // (iChannel)廠商需要接gid
      // let merchantIChannelsGid = '';
      // const queryGid = this.$route.query.gid;
      // if (queryGid) {
      //   merchantIChannelsGid = `gid=${queryGid} |`;
      // }
      // const noteInfo = `${merchantIChannelsGid} 學員中文姓名: ${formdata.studentChineseName} | 學員英文姓名: ${formdata.studentEnglishName} | 學員年齡: ${formdata.studentAge} | 學員家長姓名: ${formdata.parentName}`;

      let noteInfo = '';

      const queryGid = this.$route.query.gid;
      if (queryGid) {
        noteInfo += `gid=${queryGid} `;
      }
      const clickID = this.$route.query.utm_term;
      if (clickID) {
        this.aff_click_id = clickID;
      }

      // 必填
      if (formdata.parentName) {
        noteInfo += `學員家長姓名: ${formdata.parentName}`;
      }
      return noteInfo;
    },
    savePhoneNumber(phone) {
      localStorage.setItem('setFreecoinsCvq', true);
      localStorage.setItem('trialPhone', phone);
    },
    async submitForm(formdata) {
      const fieldChecked = this.fieldCheck(formdata.parentEmail, formdata.parentPhone, formdata.privacyPolicyCheck, formdata);
      if (fieldChecked) {
        const mediaSource = getEventMediaSource(this.$route.path, this.$route.query);
        const noteInfo = this.getNoteInfo(formdata);
        const params = {
          name: formdata.parentName, // 原為學生姓名，改為家長中文姓名
          phone: formdata.parentPhone, // jr丟入家長的電話
          email: formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        const result = await lioshutanApi.common.setMediaSource(params);
        if (result.data.data === 'freecoins_cvq') {
          this.savePhoneNumber(params.phone);
        } else if (result.data.data == null && localStorage.getItem('setFreecoinsCvq')) {
          localStorage.removeItem('setFreecoinsCvq');
        }
        if (result.status === httpCodeConstants.SUCCESS) {
          this.hasLeaveMessage();
          if (this.aff_click_id) {
            // 聯盟網
            await lioshutanApi.common.setAffiliates({
              server_subid: this.aff_click_id,
              em: formdata.parentEmail,
              ph: formdata.parentPhone,
            });
          }

          // 媒體來源包含指定字串 轉跳對應感謝頁
          const mediaSource = this.$route.query.m || '';
          switch (true) {
            case Boolean(mediaSource.includes('fb_ad_ispot')):
              this.$router.push({ name: 'childrensday2024ispotThankYou' });
              break;

            case mediaSource.includes('fb_ad_eric'):
              this.$router.push({ name: 'childrensday2024ericThankYou' });
              break;

            default:
              // 前往預設感謝頁
              this.$router.push({ name: 'childrensday2024ThankYou' });
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

.children-form {
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 20px 0px #D8C8B64D;
  padding: 20px;

  @include md {
    padding: 40px;
  }

  h3 {
    @include rwd-font(18px, 28px, 28px);
    font-weight: 900;
    margin-bottom: 20px;
  }

  /* 共用表單元素 */
  .form-input {
    display: flex;

    border-radius: 5px;
    border: 1px solid #FFA756;
    overflow: hidden;
    margin-bottom: 27px;

    .icon {
      border-right: 1px solid #FFA756;

      img {
        height: 40px;
      }
    }

    input {
      flex-grow: 1;
      border: none;

      color: var(--text-secondary);
      letter-spacing: 0.06em;
      padding: 9px 10px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .form-descriptive {
    position: relative;
    border-top: 2px dashed #ddd;
    padding-top: 20px;

    @include xl {
      margin-top: 40px;
    }

    h4 {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 10px;
      background: white;
      width: 155px;
      text-align: center;

      span {
        width: 100%;
        display: block;
        background: #FFCB15;
        color: white;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
      }
    }

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        text-align: left;

        @include md {
          min-width: 178px;
        }

        >img {
          width: 50px;
          margin-right: 10px;

          @include md {
            width: 80px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;
        }

        .shape {
          width: 32px;
        }
      }
    }
  }

  .form-privacy {
    display: flex;
    align-items: center;

    margin: 20px 0;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-major);

    a {
      font-weight: 700;
      color: #FFA756;
    }

    label {
      margin-right: 6px;
      border-radius: 2px;
      border: 1px solid #FFA756;
      width: 13px;
      height: 13px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="checkbox"] {
      display: none;

      background-color: white;

      &:checked+label {
        background-color: #FFA756;
      }
    }
  }

  button {
    background: #FFA756;
    border-radius: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: white;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;

    &:hover {
      background: #505050;
    }
  }

  .form-content>p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    a {
      color: #FFA756;
      margin-right: 4px;
    }
  }
}

.sincechildform.children-form {
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 20px 0px #D8C8B64D;
  padding: 20px;

  @include md {
    padding: 40px;
  }

  h3 {
    @include rwd-font(18px, 28px, 28px);
    font-weight: 900;
    margin-bottom: 20px;
  }

  /* 共用表單元素 */
  .form-input {
    display: flex;

    border-radius: 5px;
    border: 1px solid #75CDEC;
    overflow: hidden;
    margin-bottom: 27px;

    .icon {
      border-right: 1px solid #75CDEC;

      img {
        height: 40px;
      }
    }

    input {
      flex-grow: 1;
      border: none;

      color: var(--text-secondary);
      letter-spacing: 0.06em;
      padding: 9px 10px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .form-descriptive {
    position: relative;
    border-top: 2px dashed #ddd;
    padding-top: 20px;

    @include xl {
      margin-top: 40px;
    }

    h4 {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 10px;
      background: white;
      width: 155px;
      text-align: center;

      span {
        width: 100%;
        display: block;
        background: #FFCB15;
        color: white;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
      }
    }

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        text-align: left;

        @include md {
          min-width: 178px;
        }

        >img {
          width: 50px;
          margin-right: 10px;

          @include md {
            width: 80px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;
        }

        .shape {
          width: 32px;
        }
      }
    }
  }

  .form-privacy {
    display: flex;
    align-items: center;

    margin: 20px 0;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-major);

    a {
      font-weight: 700;
      color: #75CDEC;
    }

    label {
      margin-right: 6px;
      border-radius: 2px;
      border: 1px solid #75CDEC;
      width: 13px;
      height: 13px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="checkbox"] {
      display: none;

      background-color: white;

      &:checked+label {
        background-color: #75CDEC;
      }
    }
  }

  button {
    background: #75CDEC;
    border-radius: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: white;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;

    &:hover {
      background: #505050;
    }
  }

  .form-content>p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    a {
      color: #75CDEC;
      margin-right: 4px;
    }
  }
}
</style>
