var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "children-form",
    class: _vm.formType
  }, [_c('h3', {
    staticClass: "text-center"
  }, [_vm._v("免費英文健檢")]), _c('div', {
    staticClass: "form-content"
  }, [_c('div', {
    staticClass: "form-input"
  }, [_vm.formType === 'sincechildform' ? [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/member.svg')
    }
  })])] : [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/member-orange.svg')
    }
  })])], _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.parentName,
      expression: "form.parentName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "姓名（必填）"
    },
    domProps: {
      "value": _vm.form.parentName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "parentName", $event.target.value);
      }
    }
  })], 2), _c('div', {
    staticClass: "form-input"
  }, [_vm.formType === 'sincechildform' ? [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/phone.svg')
    }
  })])] : [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/phone-orange.svg')
    }
  })])], _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.parentPhone,
      expression: "form.parentPhone"
    }],
    attrs: {
      "type": "phone",
      "placeholder": "手機（必填）"
    },
    domProps: {
      "value": _vm.form.parentPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "parentPhone", $event.target.value);
      }
    }
  })], 2), _c('div', {
    staticClass: "form-input"
  }, [_vm.formType === 'sincechildform' ? [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/mail.svg')
    }
  })])] : [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/mail-orange.svg')
    }
  })])], _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.parentEmail,
      expression: "form.parentEmail"
    }],
    attrs: {
      "type": "email",
      "placeholder": "信箱（必填）"
    },
    domProps: {
      "value": _vm.form.parentEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "parentEmail", $event.target.value);
      }
    }
  })], 2), _c('div', {
    staticClass: "form-descriptive"
  }, [_vm._m(0), _c('ul', [_c('li', [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/gift1.png'),
      "alt": "gift1"
    }
  }), _c('div', {
    staticClass: "relative"
  }, [_vm.formType === 'sincechildform' ? [_c('img', {
    staticClass: "shape",
    attrs: {
      "src": require('@/assets/childrensday2024/free-blue.png'),
      "alt": "free"
    }
  })] : [_c('img', {
    staticClass: "shape",
    attrs: {
      "src": require('@/assets/childrensday2024/free.png'),
      "alt": "free"
    }
  })], _c('p', [_vm._v("繪本有聲書")])], 2)]), _c('li', [_c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/gift2.png'),
      "alt": "gift2"
    }
  }), _c('div', {
    staticClass: "relative"
  }, [_vm.formType === 'sincechildform' ? [_c('img', {
    staticClass: "shape",
    attrs: {
      "src": require('@/assets/childrensday2024/free-blue.png'),
      "alt": "free"
    }
  })] : [_c('img', {
    staticClass: "shape",
    attrs: {
      "src": require('@/assets/childrensday2024/free.png'),
      "alt": "free"
    }
  })], _c('p', [_vm._v("英文學習單")])], 2)])])]), _c('div', {
    staticClass: "form-privacy"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.privacyPolicyCheck,
      expression: "form.privacyPolicyCheck"
    }],
    attrs: {
      "id": "privacy-upper",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.privacyPolicyCheck) ? _vm._i(_vm.form.privacyPolicyCheck, null) > -1 : _vm.form.privacyPolicyCheck
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.privacyPolicyCheck,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "privacyPolicyCheck", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "privacyPolicyCheck", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "privacyPolicyCheck", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "privacy-upper"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/event/trial/check.svg')
    }
  })]), _c('span', [_vm._v("我同意 WUWOW 線上英文 "), _c('router-link', {
    attrs: {
      "to": {
        path: _vm.privacyUrl
      },
      "target": "_blank"
    }
  }, [_vm._v("隱私權政策")])], 1)]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.form);
      }
    }
  }, [_vm._v("免費搶先預約")]), _vm._m(1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', [_c('span', [_vm._v("好禮 2 選 1")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-center"
  }, [_c('a', {
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("0800 885 315")]), _vm._v("撥打專線，立即有專人為您服務")]);

}]

export { render, staticRenderFns }